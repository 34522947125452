import React, { useState, useCallback } from "react"
import { CSSTransition } from "react-transition-group"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"
import { Button } from "./buttons"

import setaD from "../images/svg/setaBigD.svg"
import setaF from "../images/svg/setaD.svg"
import setaE from "../images/svg/setaBigE.svg"

const Testemunhos = ({data}) => {
  const [btnE, setBtnE] = useState(false);
  const [btnD, setBtnD] = useState(false);
  const [slide, setSlide] = useState(0);
  const size = data.quotes.length * 80 + "vw";

  const changeSlide = useCallback((n, l) => {
    if(slide + n < 0){return}
    if(slide + n > l-1){return}
    setSlide(slide + n);
    document.querySelector(".container").style.left = -slide * 80 - n * 80 + "vw";
  }, [slide])

  return(
    <StyledTest>
      <h4 className={font.nexaBold + " subtitle"}>{data.subtitle}</h4>
      <h1 className={font.financierMediumItalic + " title"}>{data.title}</h1>
      <div className="slider">
        <div className="container" style={{width: size}}>
          {data.quotes.map((data, i)=>(
            <div className="slide" key={i} style={{opacity: slide===i ? "1" : "0.4"}}>
              <p className={font.nexaRegular}>{data.text}</p>
              <p className={font.nexaXBold}>{data.author}</p>
            </div>
          ))}
        </div>
      </div>
      
      <Button className="prev" onClick={()=>changeSlide(-1, data.quotes.length)} style={{opacity: slide === 0 ? "0.3" : "1"}} onMouseEnter={()=>{setBtnE(true)}} onMouseLeave={()=>{setBtnE(false)}}>
        <CSSTransition in={btnE} timeout={350} classNames={"switch"} unmountOnExit>
          <img src={setaF} alt="seta prev" className="reverse"/>
        </CSSTransition>
        <CSSTransition in={!btnE} timeout={350} classNames={"switch"} unmountOnExit>
          <img src={setaE} alt="seta prev"/>
        </CSSTransition>
      </Button>

      <Button className="next" onClick={()=>changeSlide(+1, data.quotes.length)} style={{opacity: slide === data.quotes.length-1 ? "0.3" : "1"}} onMouseEnter={()=>{setBtnD(true)}} onMouseLeave={()=>{setBtnD(false)}}>
        <CSSTransition in={btnD} timeout={350} classNames={"switch"} unmountOnExit>
          <img src={setaF} alt="seta prev"/>
        </CSSTransition>
        <CSSTransition in={!btnD} timeout={350} classNames={"switch"} unmountOnExit>
          <img src={setaD} alt="seta next"/>
        </CSSTransition>
      </Button>
    </StyledTest>
  )
}


export default Testemunhos

const StyledTest = styled.div`
  text-align: center;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 5rem;
  .subtitle{margin: 1em; color: #717171; letter-spacing: 0.3em; text-transform: uppercase;}
  .title{margin: 1em auto; color: #e6e6e6; width: 80%;}
  .slider{
    width: 80vw;
    overflow: hidden;
    margin: auto;
    position: relative;
  }
  .container{
    display: flex;
    div{width: 80vw; padding: 0 3vw;}
    position: relative;
    left: -0vw;
    transition: left 1s;
  }
  .slide{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #717171;
  }
  .prev, .next{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 70%;
    img{width: 100%;}
  }
  .next{
    right: calc(8vw - 20px);
  }
  .prev{
    left: calc(8vw - 20px);
  }
  .reverse{
    transform: rotate(180deg);
  }

  .switch-enter{
    opacity: 0 !important;
    position: absolute;
    left: 0;
  }
  .switch-enter-active{
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit{
    opacity: 1 !important;
  }
  .switch-exit-active{
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
`