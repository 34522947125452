import React from "react"
import Img from "gatsby-image"

import styled from "styled-components"

const Especialidades = ({children, data}) => {
  return(
    <StyledContainer>
      <div className="blue">
        <div className="img">
          <Img fluid={data.childImageSharp.fluid} style={{width: `42.5vw`, position: "absolute", bottom: "0", right: "0"}} imgStyle={{objectFit: "contain"}}/>
        </div>
        {children}
      </div>
    </StyledContainer>
  )
}


export default Especialidades

const StyledContainer = styled.div`
  margin: 0;
  color: #fff;
  .blue{
    position: relative;
    width: 70%;
    margin: 0 auto;
    background-color: #02374e;
    padding: calc(3rem + 48 * (100vw - 1000px) / 920) calc(4rem + 64 * (100vw - 1000px) / 920);
    border-radius: 150px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 50% 50%;
    grid-row-gap: calc(1rem + 32 * (100vw - 1000px) / 920);
    grid-column-gap: calc(1rem + 32 * (100vw - 1000px) / 920);
  }
  .img{
    position: relative;
  }
`